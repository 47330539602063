import React, { useEffect, useState } from "react";
import "../../styles/productdetail.css";

import rudrakshimage from "../Assets/rudrakshimg1.webp";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const Productdetails = () => {
  let { productId } = useParams();
  const [productData, setProductData] = useState({});

  console.log(productId, "productId");

  const fetchProductData = async () => {
    const res = await axios.get(
      `http://localhost:3002/api/v1/products/get/${productId}`
    );
    console.log(res.data.data[0], "response");
    setProductData(res.data.data[0]);
  };

  useEffect(() => {
    fetchProductData();
  }, []);

  return (
    <>
      <section className="section">
        <div className="container">
          <h1 className="product_title">
            Prabhu Pooja | {productData.productName}
          </h1>
          
          <div className="row">
            <div className="col-sm-8">
              <div className="product-img mb-5">
                <Link>
                  <img src={productData.image} alt="" />
                </Link>
              </div>
              <div className="product">
                <div className="product-tabs">
                  <button className="product-link active">Details</button>
                </div>
              </div>
              <div className="product-content">
                <div className="product-pane active">
                  <div>
                    <span>Theme:</span>
                    <span> {productData.theme}</span>
                  </div>
                  <div>
                    <span>Brand:</span>
                    <span> {productData.brand}</span>
                  </div>
                  <div>
                    <span>Colour:</span>
                    <span> {productData.colour}</span>
                  </div>
                  <div>
                    <span>Style:</span>
                    <span> {productData.style}</span>
                  </div>
                  <div>
                    <span>Material:</span>
                    <span> {productData.material}</span>
                  </div>
                  <div>
                    <span>Special Feature:</span>
                    <span> {productData.specialFeature}</span>
                  </div>
                  <div>
                    <span>Number of Pieces:</span>
                    <span> {productData.noOfPieces}</span>
                  </div>
                  <div>
                    <span>Number of Items:</span>
                    <span> {productData.noOfItems}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-4">
              <form action="" className="package-form">
                <div
                  className="product-box"
                  style={{
                    background: "rgb(248, 117, 69)",
                    color: "#fff",
                    padding: "8px 8px",
                    marginBottom: "5px",
                  }}
                >
                  <h6 className="product_current_price">
                    <strong style={{ textDecoration: "line-through" }}>
                      RS.{productData.price}
                    </strong>
                    <strong> RS.{productData.offerPrice}</strong>
                  </h6>
                </div>

                <div className="form-group">
                  <label htmlFor="">
                    Quantity: <span>*</span>
                  </label>
                  <input
                    type="number"
                    className="input"
                    required
                    min="1"
                    max="10"
                    defaultValue="1"
                  />
                </div>

                <Link to="/checkout">
                  <button className="btn">Buy Now</button>{" "}
                </Link>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Productdetails;
