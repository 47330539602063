import React, { useEffect, useState } from "react";
import "../../styles/buynowform.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

function BuyNowForm() {
  let { productId } = useParams();
  const [productData, setProductData] = useState({});

  console.log(productId, "productId");

  const fetchProductData = async() => {
    const res = await axios.get(
      `http://localhost:3002/api/v1/products/get/${productId}`
    );
    console.log(res.data.data[0], "response");
    setProductData(res.data.data[0])
  };

  useEffect(() => {
    fetchProductData();
  },[]);

  return (
    <div className="buynowform">
      <h1 className="buynow-title">Buy Now</h1>
      <div className="buynow-form-container">
        <form className="buynow-form">
          <div className="buynow-price-box">
            <h6 className="current-price-product">
              <span className="old-price">RS.{productData.price}</span>
              <span className="current-price">RS.{productData.offerPrice}</span>
            </h6>
          </div>

          <div className="form-group-box">
            <label htmlFor="quantity">
              Quantity: <span>*</span>
            </label>
            <input
              type="number"
              id="quantity"
              className="input-product"
              required
              min="1"
              max="10"
              defaultValue="1"
            />
          </div>

          <Link to="/checkout">
            <button className="buynow-btn">Buy Now</button>
          </Link>
        </form>
      </div>
    </div>
  );
}

export default BuyNowForm;
