import React, { useEffect, useRef, useState } from "react";
import logo from "../Components/Assets/logo-image-prabhupooja2.png";
import { Link, useNavigate } from "react-router-dom";
import api from "./Axios/api";
import prasadimg from "../Components/Assets/prasadimg.webp";
import prasadimg2 from "../Components/Assets/prasadimg2.webp";
import prasadimg3 from "../Components/Assets/prasadimg3.jpg";
import userprofileimg from "../Components/Assets/profileimg.png";
import "../styles/navbar.css";

function Navbar() {
  const menuRef = useRef(null);
  const openMenuBtnRef = useRef(null);
  const closeMenuBtnRef = useRef(null);
  const cartRef = useRef(null);
  const profileMenuRef = useRef(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [balance, setBalance] = useState(0);
  const [cartDropdownOpen, setCartDropdownOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthStatus = () => {
      const token = localStorage.getItem("token");
      const isVerified = localStorage.getItem("isVerified");
      const user = JSON.parse(localStorage.getItem("users"));

      if (token && isVerified === "true") {
        setIsLoggedIn(true);
        if (user) {
          setUserName(user.name);
          setUserMobile(user.mobile);
        }
      } else {
        setIsLoggedIn(false);
      }
    };

    checkAuthStatus();

    const handleLoginStatusChange = () => {
      checkAuthStatus();
    };

    window.addEventListener("loginStatusChanged", handleLoginStatusChange);

    return () => {
      window.removeEventListener("loginStatusChanged", handleLoginStatusChange);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isVerified");
    localStorage.removeItem("users");
    setIsLoggedIn(false);
    setUserName("");
    setUserMobile("");
    setProfileMenuOpen(false);
    navigate("/");
    window.dispatchEvent(new Event("loginStatusChanged"));
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchBalance();
    }
  }, [isLoggedIn]);

  const fetchBalance = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("users"));
      const response = await api.get(`/users/balance/${user.id}`);
      setBalance(response.data.balance);
    } catch (error) {
      console.error("Failed to fetch balance:", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileMenuOpen &&
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setProfileMenuOpen(false);
      }

      if (
        isMenuOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }

      if (
        cartDropdownOpen &&
        cartRef.current &&
        !cartRef.current.contains(event.target)
      ) {
        setCartDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileMenuOpen, isMenuOpen, cartDropdownOpen]);

  const handleViewCartClick = () => {
    navigate("/cart");
    setCartDropdownOpen(false);
  };

  const handleProfileToggle = () => {
    setProfileMenuOpen((prev) => !prev);
    if (isMenuOpen) setMenuOpen(false); // Close main menu if open
    if (cartDropdownOpen) setCartDropdownOpen(false); // Close cart if open
  };

  const handleDropdownToggle = (e) => {
    e.preventDefault();
    setDropdownOpen((prev) => !prev);
    if (profileMenuOpen) setProfileMenuOpen(false); // Close profile menu if open
    if (cartDropdownOpen) setCartDropdownOpen(false); // Close cart if open
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
    if (profileMenuOpen) setProfileMenuOpen(false); // Close profile menu if open
    if (cartDropdownOpen) setCartDropdownOpen(false); // Close cart if open
  };

  return (
    <div className={`header ${isMenuOpen ? "menu-open" : ""}`}>
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <div className={`menu ${isMenuOpen ? "open" : ""}`} ref={menuRef}>
          <div className="head">
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="Logo" />
              </Link>
            </div>
            <button
              type="button"
              className="close-menu-btn"
              ref={closeMenuBtnRef}
              onClick={toggleMenu}
            ></button>
          </div>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li className={`dropdown ${isDropdownOpen ? "active" : ""}`}>
              <Link to="/onlinepooja" onClick={handleDropdownToggle}>
                Services
              </Link>
              <i
                className="fa-solid fa-chevron-down"
                onClick={handleDropdownToggle}
              ></i>
              <ul className={`sub-menu ${isDropdownOpen ? "active" : ""}`}>
                <li>
                  <Link to="/membership">
                    <span>Membership</span>
                  </Link>
                </li>
                <li>
                  <Link to="/onlinepooja">
                    <span>Online Pooja</span>
                  </Link>
                </li>
                <li>
                  <Link to="/prasaddelivery">
                    <span>Prasad Delivery</span>
                  </Link>
                </li>
                <li>
                  <Link to="/astrology">
                    <span>Astrology</span>
                  </Link>
                </li>
                <li>
                  <Link to="/muhurat">
                    <span>Muhurat</span>
                  </Link>
                </li>
                <li>
                  <Link to="/ecommerce">
                    <span>E-Commerce</span>
                  </Link>
                </li>
                <li>
                  <Link to="/temple">
                    <span>Temple</span>
                  </Link>
                </li>
                <li>
                  <Link to="/pandit">
                    <span>Pandit</span>
                  </Link>
                </li>
                <li>
                  <Link to="/yoga">
                    <span>Yoga</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/enquiryform">Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className="header-right">
          <button
            type="button"
            className="icon-btn"
            onClick={() => {
              setCartDropdownOpen((prev) => !prev);
              if (profileMenuOpen) setProfileMenuOpen(false);
              if (isMenuOpen) setMenuOpen(false);
            }}
          >
            <i className="fa-solid fa-cart-plus"></i>
          </button>
          {cartDropdownOpen && (
            <div className="cart-dropdown" ref={cartRef}>
              <h3>Cart Items</h3>
              <ul className="cart-items-list">
                <li className="cart-dropdown-item">
                  <img src={prasadimg} alt="Kaju Katli Bhog" />
                  <div className="cart-item-details">
                    <p>Kaju Katli Bhog</p>
                    <p>₹ 800</p>
                  </div>
                </li>
                <li className="cart-dropdown-item">
                  <img src={prasadimg2} alt="Besan Laddu Bhog" />
                  <div className="cart-item-details">
                    <p>Besan Laddu Bhog</p>
                    <p>₹ 499</p>
                  </div>
                </li>
                <li className="cart-dropdown-item">
                  <img src={prasadimg3} alt="Chappan Bhog" />
                  <div className="cart-item-details">
                    <p>Chappan Bhog</p>
                    <p>₹ 1000</p>
                  </div>
                </li>
              </ul>
              <button className="view-cart-btn" onClick={handleViewCartClick}>
                View Cart
              </button>
            </div>
          )}
          {isLoggedIn ? (
            <div className="profile-menu" ref={profileMenuRef}>
              <img
                src={userprofileimg}
                alt="User"
                className="userimg"
                onClick={handleProfileToggle}
              />
              {profileMenuOpen && (
                <div className="profile-dropdown">
                  <p className="user-name">{userName}</p>
                  <p className="user-mobile">{userMobile}</p>
                  <div className="separator"></div>
                  <p className="user-balance">Wallet balance: ₹ {balance}</p>
                  <p className="user-booking">Your Bookings: 5</p>
                  <button onClick={handleLogout} className="logout-button">
                    Logout
                  </button>
                </div>
              )}
            </div>
          ) : (
            <Link to="/login">
              <button className="login-btn">Login</button>
            </Link>
          )}
          <button
            type="button"
            className="open-menu-btn"
            ref={openMenuBtnRef}
            onClick={toggleMenu}
          >
            <span className="line line-1"></span>
            <span className="line line-2"></span>
            <span className="line line-3"></span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
