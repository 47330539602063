import React, { useEffect, useState } from "react";
import "../styles/home.css";
import axios from "axios";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import bannerimg from "../Components/Assets/bannerimg1.png";
import homeimg from "../Components/Assets/bg-imagee.jpeg";
import homeimg1 from "../Components/Assets/new-bg4.jpg";
import homeimg2 from "../Components/Assets/bg-banner2.jpeg";
import homeimg3 from "../Components/Assets/bg-banner3.jpeg";
import homeimg4 from "../Components/Assets/bg-banner4.jpeg";
import aboutimg from "../Components/Assets/aboutimg.png";
import bappaimg from "../Components/Assets/bappa-img1.png";
import banner from "../Components/Assets/Exploreourapp-banner.png";
import { Link } from "react-router-dom";

// import aboutimg1 from "../Components/Assets/bg-imagee.jpeg";
// import aboutimg2 from "../assets/mon.jpg";
// import aboutimg3 from "../assets/thurs.jpg";
// import aboutimg4 from "../assets/tues.jpg";
// import aboutimg5 from "../assets/fri.jpg";
// import aboutimg6 from "../assets/satur.jpg";
// import aboutimg7 from "../assets/sun.jpg";

import bell from "../Components/Assets/bell.png";
import { FaWhatsapp } from "react-icons/fa";
import api from "./Axios/api";

const Home = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    // autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };

  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [editingService, setEditingService] = useState(null);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await api.get("/user/services/get");

        if (response.data.success) {
          setServices(response.data.data);
        } else {
          setError(response.data.message || "Failed to fetch services.");
        }
      } catch (error) {
        setError("Error fetching services. Please try again.");
        console.error("Error fetching services:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  function slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "")
      .replace(/\-/g, "")
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  }

  const faqs = [
    {
      question: "What is Prabhu Pooja?",
      answer:
        "Prabhu Pooja is a ritual performed to offer devotion to deities, seeking blessings for health, prosperity, and peace. At Prabhu Pooja, we make it accessible online.",
    },
    {
      question: "What are various services offered by Prabhu Pooja?",
      answer:
        "Prabhu Pooja offers a wide range of services including Membership, Online Pooja, Prasad Delivery, Astrology, E-commerce, Muhurat, and personalized pooja services tailored to your needs.",
    },
    {
      question: "How can I trust Prabhu Pooja?",
      answer:
        "Prabhu Pooja is committed to delivering authentic rituals conducted by experienced priests, ensuring transparency and customer satisfaction. We have positive reviews and testimonials from our clients.",
    },
    {
      question: "How can I book a Pooja online?",
      answer:
        "You can easily book a Pooja through the Prabhu Pooja website by selecting the Pooja you wish to perform and following the checkout process.",
    },
    {
      question: "What types of Poojas are available?",
      answer:
        "Prabhu Pooja offers a variety of Poojas including Griha Pravesh, Shanti Pooja, Navagraha Pooja, and many more.",
    },
    {
      question: "How long does a Pooja take?",
      answer:
        "The duration of a Pooja varies depending on the type, but most take between 1 to 3 hours.",
    },
    {
      question: "Is there a specific time to perform Pooja?",
      answer:
        "While many Poojas can be performed at any time, certain rituals are best performed during auspicious timings as per the Hindu calendar.",
    },
    {
      question: "What if I have special requests for the Pooja?",
      answer:
        "You can mention any specific requests during the booking process, and our priests will do their best to accommodate them.",
    },
    {
      question: "How do I make payments?",
      answer:
        "We accept various payment methods including credit/debit cards, UPI, and net banking for your convenience.",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <>
      <div className="">
        <img src={homeimg1} alt="" className="bg-image" />
      </div>

      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <img src={aboutimg} alt="" className="about-img" />
            </div>

            <div className="col-sm-6">
              <div className="title">
                <h2>About Us</h2>
                <h5>
                  How <span>Prabhu Pooja</span> Works
                </h5>
                <p>
                  Online Pooja and Astrology Services In today's digital age,
                  the ancient practices of Hindu rituals and astrology have
                  seamlessly integrated with technology to offer online Pooja
                  and astrology services. These services cater to devotees
                  worldwide, allowing them to participate in sacred rituals and
                  seek astrological guidance from the comfort of their homes.
                </p>

                <button className="about-btn">
                  <Link to="/about">Read More</Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="services">
        <div className="services-title">
          <h1>our services</h1>
          <div className="bappaimgsection">
            <img src={bappaimg} alt="" className="bappaimg" />
          </div>
        </div>

        {loading ? (
          <p>Loading services...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <div className="table-box">
            {services.map((service) => (
              <div
                className="box"
                key={service.id}
                onClick={(e) => e.currentTarget.classList.toggle("move-up")}
              >
                <Link to={`/${slugify(service.name)}`}>
                  <img src={`${service.image}`} alt={service.name} />
                  <p>{service.name}</p>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="banner">
        <div className="container">
          <img src={banner} alt="" className="bg-banner" />
        </div>
      </div>

      <div className="faq-section">
        <div className="container">
          <h2>FAQs</h2>
          <p>Clear your doubts here regarding how things work</p>
          {faqs.map((faq, index) => (
            <div className="faq-item" key={index}>
              <div className="faq-question" onClick={() => toggleFAQ(index)}>
                <h5>{faq.question}</h5>
                <span className={`arrow ${openIndex === index ? "up" : "down"}`}>&#9660;</span>
              </div>
              {openIndex === index && (
                <div className="faq-answer">{faq.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>


      <a
        href="https://wa.me/7225016699?text=Namaste"
        className="whatsapp-icon"
        target="_blank"
        rel="noopener noreferrer"
        text="welcome to prrabhu pooja"
      >
        <FaWhatsapp size={40} color="white" />
      </a>
    </>
  );
};

export default Home;
