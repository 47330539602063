import React from "react";
import "../../styles/temple.css";
import { Link } from "react-router-dom";
import khajranatempleimg from "../Assets/khajrana-temple1.jpeg";
import ujjaintempleimg from "../Assets/ujjain-temple1.jpeg";
import mapimg from "../Assets/map-temple-image.png";
import { IoCheckmark } from "react-icons/io5";

const Temple = () => {
  return (
    <div>
      {/* Header Section */}
      <div className="temple-header">
        <div className="container">
          <div className="row">
            <div className="col-sm-7">
              <div className="temple-intro">
                <h1>Visit the holy places and temples of India.</h1>
              </div>
              <div className="temple-features">
                <p className="feature-item">
                  <IoCheckmark className="feature-icon" />
                  Learn about their culture and history.
                </p>
                <p className="feature-item">
                  <IoCheckmark className="feature-icon" />
                  Find temples of your favorite gods.
                </p>
                <p className="feature-item">
                  <IoCheckmark className="feature-icon" />
                  Do charity work and donate to these temples.
                </p>
              </div>
            </div>
            <div className="col-sm-5">
              <Link to="#">
                <img src={mapimg} alt="Map of Temples" className="temple-map-image" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Temple Gallery Section */}
      <div className="temple-gallery">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <article className="temple-gallery-item">
                <Link to="/khajranatemple">
                  <img
                    src={khajranatempleimg}
                    alt="Khajrana Ganesh Mandir in Indore, Madhya Pradesh"
                    height={245}
                    className="temple-image"
                  />
                </Link>
                <div className="temple-info">
                  <h3 className="temple-title">Khajrana Ganesh Mandir</h3>
                  <p className="temple-description">Indore, Madhya Pradesh</p>
                  <div className="temple-price">₹ 1151</div>
                  <div className="temple-actions">
                    <Link className="temple-action-link" to="/khajranatemple">
                      <button>VIEW</button>
                    </Link>
                    <Link className="temple-action-link book-action-link" to="/booknowform">
                      <button>BOOK</button>
                    </Link>
                  </div>
                </div>
              </article>
            </div>

            <div className="col-sm-4">
              <article className="temple-gallery-item">
                <Link to="/ujjaintemple">
                  <img
                    src={ujjaintempleimg}
                    alt="Mahakaleshwar Mandir in Ujjain, Madhya Pradesh"
                    height={245}
                    className="temple-image"
                  />
                </Link>
                <div className="temple-info">
                  <h3 className="temple-title">Mahakaleshwar Mandir</h3>
                  <p className="temple-description">Ujjain, Madhya Pradesh</p>
                  <div className="temple-price">₹ 1151</div>
                  <div className="temple-actions">
                    <Link className="temple-action-link" to="/ujjaintemple">
                      <button>VIEW</button>
                    </Link>
                    <Link className="temple-action-link book-action-link" to="/booknowform">
                      <button>BOOK</button>
                    </Link>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Temple;
