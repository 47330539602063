import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/cart.css";
import prasadimg from "../Components/Assets/prasadimg.webp";
import prasadimg2 from "../Components/Assets/prasadimg2.webp";
import prasadimg3 from "../Components/Assets/prasadimg3.jpg";

const Cart = () => {
  // Use state to track the cart items
  const [cartItems, setCartItems] = useState([
    {
      id: 1,
      name: "Kaju Katli Bhog",
      imageUrl: prasadimg,
      price: 800,
      quantity: 1,
    },
    {
      id: 2,
      name: "Besan laddu Bhog",
      imageUrl: prasadimg2,
      price: 499,
      quantity: 2,
    },
    {
      id: 3,
      name: "Chappan Bhog",
      imageUrl: prasadimg3,
      price: 1000,
      quantity: 2,
    },
  ]);

  // Function to handle removing an item from the cart
  const handleRemoveItem = (id) => {
    const updatedItems = cartItems.filter((item) => item.id !== id);
    setCartItems(updatedItems);
  };

  // Function to handle increasing the quantity of an item
  const handleIncreaseQuantity = (id) => {
    const updatedItems = cartItems.map((item) =>
      item.id === id ? { ...item, quantity: item.quantity + 1 } : item
    );
    setCartItems(updatedItems);
  };

  // Function to handle decreasing the quantity of an item
  const handleDecreaseQuantity = (id) => {
    const updatedItems = cartItems.map((item) =>
      item.id === id && item.quantity > 1
        ? { ...item, quantity: item.quantity - 1 }
        : item
    );
    setCartItems(updatedItems);
  };

  const handleCheckout = () => {
    // Add your checkout logic here
  };

  return (
    <div className="cart-page">
      {cartItems.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        <div className="cart-container">
          <div className="cart-items">
            {cartItems.map((item) => (
              <div key={item.id} className="cart-item">
                <img src={item.imageUrl} alt={item.name} />
                <div className="cart-item-details">
                  <h2>{item.name}</h2>
                  <p>Price: ₹{item.price}</p>
                  <div className="quantity-control">
                    <button onClick={() => handleDecreaseQuantity(item.id)}>
                      -
                    </button>
                    <span>{item.quantity}</span>
                    <button onClick={() => handleIncreaseQuantity(item.id)}>
                      +
                    </button>
                  </div>
                </div>
                <button
                  onClick={() => handleRemoveItem(item.id)}
                  className="remove-button"
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
          <div className="order-summary">
            <h2>Order Summary</h2>
            <div className="summary-itemm">
              <span>Total Products:</span>
              <span>{cartItems.length}</span>
            </div>
            <div className="summary-item1">
              <span>Subtotal:</span>
              <span>
                ₹
                {cartItems.reduce(
                  (total, item) => total + item.price * item.quantity,
                  0
                )}
              </span>
            </div>
            <div className="summary-item">
              <span>Tax:</span>
              <span>₹0</span>
            </div>
            <div className="summary-item total">
              <span>TOTAL:</span>
              <span>
                ₹
                {cartItems.reduce(
                  (total, item) => total + item.price * item.quantity,
                  0
                )}
              </span>
            </div>
            <button onClick={handleCheckout} className="checkout-button">
              Proceed to Checkout
            </button>
          </div>
        </div>
      )}
      <Link to="/" className="continue-shopping">
        Continue
      </Link>
    </div>
  );
};

export default Cart;
