import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../styles/ecommerce.css";
import api from "../Axios/api";
import toast from "react-hot-toast";

const Ecommerce = () => {
  const [productData, setProductData] = useState([]);
  const [filter, setFilter] = useState("");
  const [filterBy, setFilterBy] = useState("name");
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const fetchData = async () => {
    const res = await api.get("/products/get");
    setProductData(res.data.data[0]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const user = JSON.parse(localStorage.getItem("users"));
  const addToCart = async (productId) => {
    try {
      const products = [{ productId, quantity: 1 }];
      const res = await api.post("/cart/createOrUpdate", {
        user_id: user.id,
        products,
      });

      if (res.data.success) {
        toast.success("Product added to cart successfully!");
      } else {
        toast.error("Failed to add product to cart.");
      }
    } catch (error) {
      toast.error("An error occurred while adding the product to the cart.");
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const toggleFilterType = (type) => {
    setFilterBy(type);
    setFilter("");
    setShowFilterOptions(false);
  };

  const toggleFilterOptions = () => {
    setShowFilterOptions(!showFilterOptions);
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setShowFilterOptions(false);
  };

  const filteredProductNames = productData.map(
    (product) => product.productName
  );
  const filteredPrices = productData.map((product) => product.price);

  const filteredProducts = selectedProduct
    ? [selectedProduct] 
    : productData.filter((product) =>
        filterBy === "name"
          ? product.productName.toLowerCase().includes(filter.toLowerCase())
          : product.price.toString().includes(filter)
      );

  return (
    <>
      <div className="sub_header">
        <div className="container">
          <div className="subheader_inner">
            <div className="subheader_text">
              <h1>E-commerce</h1>
            </div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="btn-link" to="/">
                    Home
                  </Link>
                </li>

                <li className="breadcrumb-item active">E-commerce</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <section className="section" style={{ paddingTop: ".5rem" }}>
        <div className="container">
          <h1 className="section-title" style={{ marginBottom: "3rem" }}>
            Products
          </h1>

          {/* Filter Section */}
          <div className="card filterbox shadow-sm mb-4">
            <div className="card-header bg-white d-flex justify-content-between">
              <div>
                <button
                  className={filterBy === "name" ? "active-filter" : ""}
                  onClick={() => {
                    toggleFilterType("name");
                    toggleFilterOptions();
                  }}
                >
                  Filter by Name
                </button>
                <button
                  className={filterBy === "price" ? "active-filter" : ""}
                  onClick={() => {
                    toggleFilterType("price");
                    toggleFilterOptions();
                  }}
                >
                  Filter by Price
                </button>
                <input
                  type="text"
                  value={filter}
                  onChange={handleFilterChange}
                  placeholder={
                    filterBy === "name"
                      ? "Search by name..."
                      : "Search by price..."
                  }
                />
              </div>
            </div>
          </div>

          {/* Filter Options Dropdown */}
          {showFilterOptions && (
            <div className="filter-options-popup">
              <h5>Select {filterBy === "name" ? "Product" : "Price"}</h5>
              <ul>
                {filterBy === "name"
                  ? filteredProductNames.map((name, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          handleProductClick(
                            productData.find(
                              (product) => product.productName === name
                            )
                          )
                        }
                      >
                        {name}
                      </li>
                    ))
                  : filteredPrices.map((price, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          handleProductClick(
                            productData.find(
                              (product) => product.price === price
                            )
                          )
                        }
                      >
                        Rs.{price}
                      </li>
                    ))}
              </ul>
              <button onClick={() => setShowFilterOptions(false)}>Close</button>
            </div>
          )}

          <div className="product-container">
            {filteredProducts.map((product) => (
              <div className="tp-box1" key={product.id}>
                <div className="tp-img">
                  <Link to={`/productdetails/${product.id}`}>
                    <img src={product.image} alt={product.productName} />
                  </Link>
                </div>
                <div className="tp-box-content">
                  <Link to={`/productdetails/${product.id}`}>
                    <h6>{product.productName}</h6>
                  </Link>
                  <div className="price-booknow">
                    <div className="price-group">
                      <p className="original-price">Rs.{product.price}</p>
                      <p className="current-price">
                        <strong>
                          <span>Rs.{product.offerPrice}</span>
                        </strong>
                      </p>
                    </div>
                    <Link
                      className="booknow-btn"
                      to={`/buynowform/${product.id}`}
                    >
                      Buy Now
                    </Link>
                    <div
                      className="booknow-btn"
                      onClick={() => addToCart(product.id)}
                    >
                      Add to Cart
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Ecommerce;
